
$body-bg: #EDE9DE;
$dropdown-bg: #fff;
$card-bg: #fff;
$table-bg: $body-bg;
$modal-content-bg: #fff;
$link-color: #0D74C4;
$link-hover-color: #F60;
$link-decoration: none;
$icon-font-path: "/fonts/";
$color-white: #fff;
$input-bg: #fff;
$nav-tabs-link-active-bg: #fff;
