// Core variables and mixins
@import './bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons';

app-index {
  display: block;
}

.navbar {
  margin-bottom: 1rem;
}

.navbar-brand svg {
  width: 50px;
  height: 50px;
  margin: -18px 0;

  path {
    fill: #9d9d9d;
  }

  &:hover {
    path {
      fill: $color-white;

      @include transition(fill 0.2s);
    }
  }
}

body {
  &.gallery {
    padding-top: 0;

    .container-fluid {
      padding: 0;
    }
  }
}


@include media-breakpoint-up(md) {
  .navbar {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    top: 0;
    margin-bottom: 16px;
  }

  body {
    padding-top: 74px;

    &.gallery {
      padding-top: 0;
    }
  }
}

h1,
h2,
h3 {
  small {
    color: $text-muted;
  }
}

$color-white: #fff;

.page-header,
legend {
  border-bottom-color: $color-white;
}

.label {
  font-weight: inherit;
}

/* a.label:visited {
    color: $color-white;
} */

a {
  @include transition(color 0.2s);

  &:hover,
  &:active {
    .badge {
      text-decoration: none;
    }
  }
}

.btn {
  @include transition(color 0.2s, background-color 0.2s, border-color 0.2s);
}

.jcrop-holder img,
img#picture-to-crop {
  max-width: none;
}

.picture-behaviour {
  font-size: 12px;
  margin-bottom: 0;
}

.card {
  &.vote-accept {
    box-shadow: 0 0 3px rgb(0 128 0 / 70%);
  }

  &.vote-remove {
    box-shadow: 0 0 3px rgb(255 0 0 / 70%);
  }

  &.vote-neutral {
    box-shadow: 0 0 3px rgb(240 173 78 / 70%);
  }
}

.brand-icons {
  > div {
    min-height: 70px;
  }
}

.comments {
  .cancel {
    display: none;
  }

  .replies {
    legend {
      display: none;
    }

    form {
      margin-left: 100px;
    }

    .cancel {
      display: block;
    }
  }

  .message {
    margin-bottom: 20px;

    .photo {
      width: 70px;
      float: left;

      @include media-breakpoint-down(sm) {
        display: none;
      }

      img {
        max-width: 70px;
      }
    }

    .content {
      min-height: 70px;
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-left: 100px;
      }
    }

    .comment-restore-button,
    .deleted-message {
      display: none;
    }

    .text {
      background: white;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-shadow: 0 1px 1px rgb(0 0 0 / 7.5%);
      padding: 10px;
      min-height: 70px;
      margin-bottom: 10px;
      word-wrap: break-word;
    }

    .vote {
      margin-right: 20px;

      .vote-up {
        color: #000;

        &:hover,
        &.active {
          color: #090;
        }
      }

      .vote-down {
        color: #000;

        &:hover,
        &.active {
          color: #c00;
        }
      }

      .value {
        font-weight: bold;

        &.zero {
          cursor: default;
          text-decoration: none;
          color: #000;
        }
      }
    }

    &.deleted {
      > .content {
        > .deleted-message {
          display: block;
        }

        > .text {
          display: none;
        }

        > div {
          > .vote,
          > .comment-remove-button {
            display: none;
          }

          > .comment-restore-button {
            display: inline-block;
          }
        }
      }

      > .photo {
        display: none;
      }
    }

    .replies {
      margin-left: 30px;
    }
  }
}

.avatar,
.max-width {
  max-width: 100%;
}

.popover-handler {
  cursor: help;
}

img.avatar {
  border: 1px black solid;
}

.brand-hero-unit {
  background-color: #f5f5f5;

  .logo {
    float: left;
    margin: -7px 20px 20px 0;
  }
}

.hero-unit {
  background-color: #f5f5f5;
}

.no-photo {
  text-align: center;
  min-height: 116px;
  line-height: 116px;
  opacity: 0.5;
  background: #fff;

  .bi-camera {
    vertical-align: middle;
  }

  a.text-success {
    float: right;
    margin: 5px 5px 0 -17px;

    &:hover {
      text-decoration: none;
    }
  }
}

.card.card-body.transparent {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}

.thumbnail-inline {
  display: inline-block;
  margin-bottom: 0;
}

.nav-tree .nav .nav {
  padding-left: 15px;
}

sup.design-brand {
  padding: 1px 2px;
  background-color: white;
  font-weight: normal;
  white-space: nowrap;
}

span.count {
  font-size: 11px;
  color: #666;
  white-space: nowrap;

  span,
  a {
    font-size: 11px;
    color: #c00;
    text-decoration: none;
  }

  .popover {
    font-size: 13px;
    color: #333;
  }
}

.user {
  a {
    font-weight: bold;
    color: #33c;
  }

  &.green-man a {
    color: #393;
  }

  &.long-away a {
    color: #999;
  }
}

ul.car-links {
  margin: 0 0 20px;
  list-style-type: none;
  clear: both;
  padding: 0;

  li {
    display: inline;
    padding: 0;
    margin: 0 10px 0 0;
  }
}

span.unit {
  color: #999;
}

.picture-preview-medium .languages sup {
  font-family: monospace;
}

.tree.stock-only {
  .tuning,
  .sport {
    display: none;
  }
}

.produced {
  white-space: nowrap;
}

.diff {
  ins {
    background: #eaf2c2;
    color: #406619;
    text-decoration: none;
  }

  del {
    text-decoration: none;
    color: #b30000;
    background: #fadad7;
  }
}

.faq blockquote {
  margin-top: 30px;
  font-style: italic;
}

.index-brands {
  font-size: 18px;
}

.car-node {
  > a {
    > .bi-folder2-open {
      display: none;
    }

    > .bi-folder2 {
      display: inline-block;
    }
  }

  > ul {
    display: none;
  }

  &.open {
    > a {
      > .bi-folder2-open {
        display: inline-block;
      }

      > .bi bi-folder2 {
        display: none;
      }
    }

    > ul {
      display: block;
    }
  }
}

.nav-pills > li > a {
  @include transition(color 0.2s, background-color 0.2s);
}

iframe {
  border: 0;
}

.item-list-item {
  margin-bottom: 40px;
}

.share-dialog {
  display: none;
}

.form-group > .twitter-typeahead {
  display: block !important;
}

.carousel-caption {
  h3 small {
    color: #fff;
  }
}
